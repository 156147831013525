import { styled, Button } from "@mui/material";

export const CustomizedButton = styled(Button)({
    borderRadius: '40px',
    color: 'black',
    backgroundColor: 'white',
    fontSize: '1em',
    fontWeight: 'bold'
});

export const CustomizedButton2 = styled(Button)({
    borderRadius: '40px',
    color: 'white',
    backgroundColor: '#42ad59',
    fontSize: '1em',
    fontWeight: 'bold'
});