import React from "react";
import styles from "./ReviewsBlock.module.css";

export const ReviewsBlock:React.FC<{}> = () => {

    return (
        <div className={styles.reviews}>
            <div className={styles.review}>
                <h1 className={styles.name}>Игорь Сидоров</h1>
                <p className={styles.text}>Хочу поделиться своим положительным опытом работы с компанией "Перевозка самосвалы". Я обратился к ним для выполнения земляных работ на своем участке, и остался очень доволен результатом!</p>
                <p className={styles.text}>Работа началась в оговоренные сроки, и я был приятно удивлён качеством выполнения. Кроме того, компания предложила конкурентоспособные цены, что также приятно удивило. Я получил отличный результат без лишних затрат!</p>
            </div>
            <div className={styles.review}>
                <h1 className={styles.name}>Анна Петрова</h1>
                <p className={styles.text}>Недавно я воспользовалась услугами для выполнения земляных работ на своем строительном участке, и хочу поделиться своим опытом.</p>
                <p className={styles.text}>Меня проконсультировали по всем вопросам и помогли выбрать оптимальные решения для моего проекта. Я заказала выемку грунта и доставку стройматериалов, все прошло на высшем уровне! Цена показалась мне довольно разумной, учитывая высокое качество работы. Спасибо компании "Перевозка самосвалы" за проделанную работу!</p>
            </div>
            <div className={styles.review}>
                <h1 className={styles.name}>Александр Смирнов</h1>
                <p className={styles.text}>Хочу выразить свою благодарность команде за отличный сервис и профессионализм! Я недавно заказал услуги по перевозке грунта и выполнению земляных работ для своего дома, и остался очень доволен результатом.</p>
                <p className={styles.text}>Работы были выполнены в срок, а качество оказалось на высоте. Оператор помог мне с выбором и помог разобраться со всеми моими вопросами. Также хочу отметить весьма низкие цены на услуги по сравнению с другими компаниями в Москве, что очень удивляет.</p>
            </div>
        </div>
    )
}